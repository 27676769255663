import React, { useCallback, useState, useMemo } from "react";
import { Pt, Physics, Group, Line, Create, Sound, Triangle, Const, Geom } from "pts";
import { PtsCanvas } from "react-pts-canvas";

export const Collinear = ({background, name}) => {
  // const soundRef = useRef(null);
  const [pairs, setPairs] = useState(null);

  const handleStart = useCallback((bound, space) => {
      let tpairs = [];
      let r = space.size.minValue().value/2;
      // create 200 lines
      let center = space.center
      if (space.size.x > space.size.y) {
          center.x = space.size.x - r - r / 3
      } else {
          center.y = space.size.y - r - r / 3
      }
      console.log(space.size, center)
      for (let i=0; i<200; i++) {
        let ln = new Group( Pt.make(2, r, true), Pt.make(2, -r, true) );
        ln.moveBy(center).rotate2D( i*Math.PI/200, center);
        tpairs.push(ln );
      }
      setPairs(tpairs)
      console.log("handle start")
  }, []);

  const handleAnimate = useCallback( (space, form) => {

      if (pairs == null) {
          return
      }
      let center = space.center
      let r = space.size.minValue().value/2;
      center.x = center.x
      if (space.size.x > space.size.y) {
          center.x = space.size.x - r - r / 3
      } else {
          center.y = space.size.y - r - r / 3
      }
      for (let i=0, len=pairs.length; i<len; i++) {

        // rotate each line by 0.1 degree and check collinearity with pointer
        let ln = pairs[i];
        ln.rotate2D( Const.one_degree/10, center );
        let collinear = Line.collinear( ln[0], ln[1], space.pointer, 0.5);
        

        if (collinear) {
            //form.stroke("#fff").line(ln);
        } else {

          // if not collinear, color the line based on whether the pointer is on left or right side
          let side = Line.sideOfPt2D( ln, space.pointer );
          form.stroke( (side<0) ? "rgba(241,251,8,.3)" : "rgba(255,255,255,.3)" ).line( ln );
        }
         form.fillOnly("rgba(255,255,255,1").points( ln, 0.5);
      }

  }, [pairs]);


  return (
    <PtsCanvas
      name={name}
      background={background}
      onStart={handleStart}
      height="800px"
      onAnimate={handleAnimate}
    />
  );

}
