import React, {useEffect, useState } from 'react'
import { Layout,  Card } from 'antd';
import { CSSTransition } from "react-transition-group";
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/images/logo-dark.svg'
import SaoNetwork from '../../assets/images/home/saonetwork.png' 
import Joint from '../../assets/images/home/joint.png' 
import ChangeLog from '../../assets/images/home/changelog.jpg' 
import Storverse from '../../assets/images/home/storverse.jpeg' 
//@ts-ignore
import { ReactComponent as Chainlink } from '../../assets/images/home/Chainlink.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {
      IconLookup,
      IconDefinition,
      findIconDefinition
} from '@fortawesome/fontawesome-svg-core'
import Cobak from '../../assets/images/home/cobak.png'
import Thegraph from '../../assets/images/home/thegraph.png'
import {ReactComponent as TwitterLogo} from '../../assets/images/home/svg/Twitter.svg'
import {ReactComponent as MediumLogo} from '../../assets/images/home/svg/Medium.svg'
import {ReactComponent as TelegramLogo} from '../../assets/images/home/svg/Telegram.svg'
import { Collinear } from "./canvas.jsx";

import './index.less';

const { Header, Content } = Layout;
const HeaderContent = styled.div`
    max-width: 1200px;
    padding: 0 50px;
    @media (max-width: 767px) {
        padding: 0 20px;
    }
`

const Introduction = styled.div`
    display: flex;
    align-items: left;
    background: #224cb1;
    background-size: cover;
    h1{
        margin-bottom: 20px;
        font-size: 64px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        @media (max-width: 767px) {
            font-size: 40px;
        }
    }
    p{
        font-size: 24px;
        color: #6B7280;
        font-weight: 400;
        line-height: 1.2;
    }
    .slogan {
        padding-top: 300px;
        float:right;
        padding-left: 200px;
        z-index: 1;
        @media (max-width: 768px) {
            padding-top: 100px;
            padding-left: 0;
        }
    }

    .pts_collinear {
        width: 100%;
        float:right;
        height: 700px;
        min-height: 500px;
        z-index: 0;
        @media (max-width: 768px) {
           position: absolute;
           height: 300px;
           min-height: 300px;
           .pts_collinear-canvas{
               height: 300px!important;
           }
        }
    }
`


const Solution = styled.div`
    background: #FFF;
    padding: 30px 0;
    @media (max-width: 768px) {
        padding: 100px 0 30px;
    }
    &>div{
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 50px;
        @media (max-width: 767px) {
            padding: 0 20px;
            margin-top: -10px;
        }
    }
    .product{
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: -25%;
            width: 100%;
            height: 100%;
            background-size: 65%;
            background-position: center;
        }
        @media (max-width: 767px) {
            &:before{
                display: none;
            }
        }
        h3{
            margin-top: 0;
            font-weight: 700;
            font-size:24px;
        }
        .img{
            display: none;
            @media (max-width: 767px) {
                display: block;
            }
        }
    }
`

const Partners = styled.div`
    background: #fff;
    &>div{
        margin: 0 auto;
        max-width: 1200px;
        padding: 50px 50px;
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
`
const StyledLink = styled.a`
    display: inline-block;
    margin-right: 20px;
    border-radius: 8px;
    svg{
        width: 32px;
        .cls-1{fill:#fff;}
        .cls-2{fill:#6B7280;}
        &:hover{
            .cls-1{fill:#6B7280;}
            .cls-2{fill:#3C17B8;}
        }
    }
`

const Copyright = styled.div`
    font-size: 15px;
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    @media (max-width: 767px) {
        font-size: 12px;
    }
`

const Hedralabs = styled.div`
    font-size: 12px;
    margin-top: 35px;
    margin-bottom: 50px;
`

const Home: React.FC = () =>  {

    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(128);
    useEffect(() => {
        window.addEventListener('scroll', scrollHeader, false);
        return () => {
            window.removeEventListener('scroll', scrollHeader, false);
        }
    }, [])


    const scrollHeader = () => {
        if (!document.querySelector('.ant-layout-header')) {
            return;
        }

        var scrollTop = document.documentElement.scrollTop;
        let height = 128;
        if (scrollTop < 0) {
            height = 128
        } else if (scrollTop < 256) {
            height = 128 - 0.21875 * scrollTop
        } else {
            height = 72
        }
        setScrollHeight(height)
    }
  
    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 767px)");
      mediaQuery.addListener(handleMediaQueryChange);
      handleMediaQueryChange(mediaQuery);
  
      return () => {
        mediaQuery.removeListener(handleMediaQueryChange);
      };
    }, []);
  
    const handleMediaQueryChange = (mediaQuery: any) => {
      if (mediaQuery.matches) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
  
    const toggleNav = () => {
      setNavVisibility(!isNavVisible);
    };
    library.add(fas)
    const Lookup: IconLookup = { prefix: 'fas', iconName: 'arrow-up-right-from-square' }
    const arrowIconDefinition: IconDefinition = findIconDefinition(Lookup)
    

    return (
        <Layout className="homePage">
            <Header>
                <HeaderContent className="header" style={{height: `${isSmallScreen ? 64 : scrollHeight}px`}}>
                    <Logo fill="#fff"/>
                    <CSSTransition
                        in={!isSmallScreen || isNavVisible}
                        timeout={350}
                        classNames="NavAnimation"
                        unmountOnExit
                    >
                        <nav className="Nav">
                            <a target="_blank" href="https://saonetwork.medium.com/">About</a>
                            <a target="_blank" href="https://saonetwork.medium.com/">Blog</a>
                        </nav>
                    </CSSTransition>
                    <button onClick={toggleNav} className="Burger">
                        <MenuOutlined />
                    </button>
                </HeaderContent>
            </Header>
            <Content>
                <Introduction>
                    {<div className="slogan">
                        <h1>
                            Open, Smart and Secure Web3 Sevices for You
                        </h1>
                </div>}
                     <Collinear name="pts_collinear" background="#224cb1" /> 
                </Introduction>
                <Solution id="Product">
                    <div>
                        <h2>Empower your business with our Web3 technologies.</h2>
                        <p>
Hedra Labs is dedicated to advancing the development and adoption of Web3 technologies and building a more open and decentralized web with infrastructures, decentralized data services and Web3 tools
                        </p>
                        <div className="products">
                            <Card
                                cover={<img alt="example" src={SaoNetwork} />}
                                onClick={() => {window.open("https://sao.network")}}
                            >
                                <h3>SAO Network
                                    <a target="_blank" href="https://sao.network"><FontAwesomeIcon icon={arrowIconDefinition} /></a>
                                </h3>
                                <p> 
                                    Store and manage your own content in Web3.
                                </p>
                            </Card>
                            <Card
                                cover={<img alt="example" src={Joint} />}
                                onClick={() => {window.open("https://app.hedralabs.org")}}
                            >
                                <h3>DeFi on Filecoin
                                    <a target="_blank" href="https://app.hedralabs.org/"><FontAwesomeIcon icon={arrowIconDefinition} /></a>
                                </h3>
                                <p>
                                    Filecoin Joint Mining, to earn FIL by easy staking
                                </p>
                            </Card>
                            <Card
                                cover={<img alt="example" src={Storverse} />}
                                onClick={() => {window.open("https://storverse.app")}}
                            >
                                <h3>Storverse
                                    <a target="_blank" href="https://storverse.app"><FontAwesomeIcon icon={arrowIconDefinition} /></a>
                                </h3>
                                <p>
                                    Decentralized storage, an easy-to-use storage platform based on IPFS
                                </p>
                            </Card>
                            <Card
                                cover={<img alt="example" src={ChangeLog} />}
                                onClick={() => {window.open("https://nft.hedralabs.org")}}
                            >
                                <h3>NFT ChangeLog
                                    <a target="_blank" href="https://nft.hedralabs.org"><FontAwesomeIcon icon={arrowIconDefinition} /></a>
                                </h3>
                                <p>
                                    Hot collections, A Powerful search engine for web3 content like NFTs
                                </p>
                            </Card>
                        </div>
                    </div>
                </Solution>
                {/* <Partners>
                    <div>
                        <h2 style={{textAlign: 'center'}}>Partners</h2>
                        <div
                            className="parteners2"
                        >
                            <div>
                                <Chainlink />
                            </div>
                            <div>
                                <img src={Cobak}/>
                            </div>
                            <div>
                                <img src={Thegraph}/>
                            </div>
                        </div>
                    </div>
                </Partners> */ }
                <div className='footer'>
                    <div className='content'>
                        <div className="links">
                            <Hedralabs>
                                <Logo fill="#fff"/>
                            </Hedralabs>  
                            <div>
                                <StyledLink target='_blank' href="https://saonetwork.medium.com/">
                                    <MediumLogo fill="#fff"/>
                                </StyledLink>
                                <StyledLink target='_blank' href="https://discord.com/invite/q58XsnQqQF">
                                    <TelegramLogo fill="#fff"/>
                                </StyledLink>
                                <StyledLink target='_blank' href="https://twitter.com/SAONetwork">
                                    <TwitterLogo fill="#fff"/>
                                </StyledLink>
                            </div>
                        </div>
                        <div className="links">
                            <h3>Products</h3>
                            <ul>
                                <li><a target="_blank" href="https://sao.network">SAO Network</a></li>
                                <li><a target="_blank" href="https://storverse.app">Storverse</a></li>
                                <li><a target="_blank" href="https://app.hedralabs.org">DeFi on Filecoin</a></li>
                                <li><a target="_blank" href="https://nft.hedralabs.org">NFT ChangeLog</a></li>
                            </ul>
                        </div>
                        <div className="links">
                            <h3>Developers</h3>
                            <ul>
                                <li>Docs</li>
                                <li>Data Store API</li>
                            </ul>
                        </div>
                        <div className="links">
                            <h3>Company</h3>
                            <ul>
                                <li>About us</li>
                                <li>Term of Service</li>
                                <li>Privacy Policy</li>
                            </ul>
                        </div>
                        <div className="links">
                            <h3>Support</h3>
                            <ul>
                                <li>Blog</li>
                                <li>FAQ</li>
                            </ul>
                        </div>
                    </div>
                    <Copyright>
                             ©Copyright 2023 Hedra Labs. All Rights Reserved.
                    </Copyright>
                </div>
            </Content>     
        </Layout>
    )
}

export default Home;
